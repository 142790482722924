import { Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";

/* IMAGES */
import AmberwoodRELogo from "../assets/amberwood-re-logo.png";

/* ICONS */
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import { IoMail, IoCall } from "react-icons/io5";

/* REDUX */
import actions from "../redux/actions/userAction";
import { useDispatch } from "react-redux";

const Header = () => {
  const dispatch = useDispatch();
  return (
    <header
      className={`header ${window.location.pathname === "/" ? "" : "dark"}`}
    >
      <div className="top-header">
        <div className="container">
          <div className="header-links">
            <div className="left-section">
              <a
                href="https://www.instagram.com/christievilanorealestate/"
                target="_blank"
                rel="noreferrer"
                className="social-media-box"
              >
                <AiFillInstagram color="#FFF" size="18" />
                <p>Instagram</p>
              </a>
              <a
                href="https://www.facebook.com/christie.vilano"
                target="_blank"
                rel="noreferrer"
                className="social-media-box"
              >
                <AiFillFacebook color="#FFF" size="18" />
                <p>Facebook</p>
              </a>
            </div>
            <div className="right-section">
              <a
                href="mailto:christievilano.re@gmail.com"
                className="social-media-box"
              >
                <IoMail color="#FFF" size="18" />
                <p>christievilano.re@gmail.com</p>
              </a>
              <a href="tel:+15629724222" className="social-media-box">
                <IoCall color="#FFF" size="18" />
                <p>(562) 972-4222</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="header-wrap">
        <div className="container">
          <div className="header-links">
            <Link to="/">
              <div className="logo-box">
                <img
                  src={AmberwoodRELogo}
                  className="company-logo"
                  alt="Amberwood Real Estate Inc"
                />
              </div>
            </Link>
            <div className="navigation-links">
              <Navbar expand="xl">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Offcanvas id="offcanvas-expand-lg" placement="end">
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title id="offcanvas-expand-lg">
                      <Link to="/">
                        <div className="logo-box">
                          <img
                            src={AmberwoodRELogo}
                            alt="Amberwood Real Estate Inc."
                          />
                        </div>
                      </Link>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="main-menu">
                      <Nav.Link href="/" className="menu-link">
                        Home
                      </Nav.Link>
                      <Nav.Link href="/buying" className="menu-link">
                        Buying
                      </Nav.Link>
                      <Nav.Link href="/selling" className="menu-link">
                        Selling
                      </Nav.Link>
                      <Nav.Link href="/active-listings" className="menu-link">
                        Active Listings
                      </Nav.Link>
                      <Nav.Link href="/blogs" className="menu-link">
                        Blogs
                      </Nav.Link>
                      <Nav.Link
                        onClick={() => {
                          dispatch(
                            actions.setContactUsHeader(
                              "Fill out the details to book an appointment"
                            )
                          );
                        }}
                        href="/contact-me"
                        className="menu-link"
                      >
                        Contact Me
                      </Nav.Link>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Navbar>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
